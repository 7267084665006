import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { uploadFile } from "../helpers";
import AdditionalInformation from "./AdditionalInformation";

function PortalCard(props) {
    let auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    let buttons = props.card.hasOwnProperty('buttons') ? props.card.buttons : [];
    if(props.card.hasOwnProperty('button')) {
      buttons.push(props.card.button);
    }

    if(props.card.hasOwnProperty('copyButton')) {
      buttons.push({
          onClick: () => {
            navigator.clipboard.writeText(props.card.copyButton.text);
          },
          // link: 'javascript:navigator.clipboard.writeText("'+props.card.copyButton.text+'")',//item.url,
          text: props.card.copyButton.label,
          target: '_self',
      });
    }

    return (
        <div className={`flex flex-col items-center block p-3 md:p-6 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 ${props.className ?? ''}`}>
          <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 text-center">{props.card.name}</h5>
          {props.card.description && typeof props.card.description !== 'string' && <p className="font-normal text-center text-gray-700">{props.card.description}</p>}
          {props.card.description && typeof props.card.description === 'string' && <p className="font-normal text-center text-gray-700" dangerouslySetInnerHTML={{__html: props.card.description }}></p>}

          {props.card.additional_info && <AdditionalInformation data={props.card.additional_info}/>}

          {buttons.length > 0 && (<div className="-ml-2">{buttons.map((button, index) => {
            let isFileUpload = button.hasOwnProperty('type') && button.type === 'upload';

            if(isFileUpload) {
              return (
              <div onClick={() => {
                if(isLoading) return;
                let input = document.createElement('input');
                input.type = 'file';
                input.onchange = _this => {
                        console.log(input.files[0]);
                        setIsLoading(true);
                        uploadFile({
                          data: {
                            type: button.id ?? 'file',
                            file: input.files[0]
                          }
                        }).then((response) => {
                          // replace the user's hazmat card
                          // this doesn't trigger the effect in wallet, so we just reload for now
                          // let user = auth.user;
                          // user.files.push(response.data);
                          //auth.setUser(user);
                          // todo: replace user's hazmat card without reloading
                          window.location.reload();
                        }).finally(() => {
                          setIsLoading(false);
                        });
                    };
                input.click();
              }} className="cursor-pointer inline-flex items-center ml-2 mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                  {isLoading === true && <em>Loading...</em>}
                  {isLoading !== true && <>
                  {button.text}
                  <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                  </>}
              </div>
              );
            }

            {/* let Object;// = button.hasOwnProperty('linkTo') ? Link : a; */}
            let linkProp;// = {};

            const linkChildren = <>
              {button.completed && (<svg className="mr-2 -ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>)}
                {button.text}
                {!button.completed && (<svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>)}
            </>;

            if(button.hasOwnProperty('linkTo')) {
              linkProp = {to: button.linkTo};
              return <Link key={index} {...linkProp} className={`${index >= 0 ? 'ml-2' : ''} inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300`}>{linkChildren}</Link>;
            } else if(button.hasOwnProperty('onClick')) {
              return <div key={index} onClick={button.onClick} className={`cursor-pointer ${index >= 0 ? 'ml-2' : ''} inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300`}>{linkChildren}</div>;
            } else {
              linkProp = {
                href: button.link,
                target: button?.target || '_blank',
              };
              return <a key={index} {...linkProp} className={`${index >= 0 ? 'ml-2' : ''} inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300`}>{linkChildren}</a>;
            }

          })}</div>)}

          {/*
          {props.card.button && isFileUpload === false && (
            <a href={props.card.button.link} target={props.card.button?.target || '_blank'} className="inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                {props.card.button.completed && (<svg className="mr-2 -ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>)}
                {props.card.button.text}
                {!props.card.button.completed && (<svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>)}
            </a>
          )}
          {isFileUpload === true && (
            <div onClick={() => {
                let input = document.createElement('input');
                input.type = 'file';
                input.onchange = _this => {
                        let files =   Array.from(input.files);
                        console.log(files);
                    };
                input.click();
            }} className="cursor-pointer inline-flex items-center mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                {props.card.button.text}
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </div>
          )}
          */}
        </div>
    );
}

export default PortalCard;

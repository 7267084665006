import {
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
} from '@chakra-ui/react';
import { addMonths, differenceInCalendarDays, format, parse, parseISO } from "date-fns";
import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { authenticatedApi } from "../Api";
import { useAuth } from "../AuthProvider";
import { ReactFormeoRenderer } from "../Routes/Forms/Formeo";
import PortalLoader from "./PortalLoader";
import { BeatLoader } from 'react-spinners';
// import './FormFill.scss';

const PersonalInfoUpdate = () => {
    const auth = useAuth();
    const uuid = '690cb175-1122-4fa0-b798-eb2e2bf893d7';

    const [form, setForm] = useState(null);
    const [formSchema, setFormSchema] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);

    let needsToUpdatePersonalInfo = false;
    if(auth.user.contact_info_updated_at === null) {
        needsToUpdatePersonalInfo = true;
    } else {
        let renewalDate = parseISO(auth.user.contact_info_updated_at);
        let daysDifference = differenceInCalendarDays(renewalDate, new Date());
        needsToUpdatePersonalInfo = Math.abs(daysDifference) > 365;
    }

    useEffect(() => {
        if(!needsToUpdatePersonalInfo) return;

        setIsLoading(true);
        authenticatedApi.get(`forms/${uuid}`).then(response => {
            let schema = response.data.schema;
            let formData = typeof schema !== 'string' ? JSON.stringify(schema) : schema;

            // fix null objects
            // todo: this probably needs adjustment
            // formData = formData.replaceAll('null', '""');

            setForm(response.data);
            setFormSchema(formData);
        }).finally(() => {
            setIsLoading(false);
        });

    }, []);

    if(!needsToUpdatePersonalInfo) {
        return null;
    }

    if(isLoading || form === null) {
        return <PortalLoader text="Loading..." />;
    }

    // if is submitted, display success message
    if(isSubmitted) {
        return(
            <div className="flex flex-col block p-6 bg-yellow-50 rounded-lg border border-gray-200 shadow-md mb-6">
                <p className={`text-md font-semibold tracking-tight text-yellow-800`}>Review Your Information</p>
                <Alert status='success' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    Thank you for updating your information.
                </Alert>
            </div>
        );
    }

    return (
        <div className="flex flex-col block p-6 bg-yellow-50 rounded-lg border border-gray-200 shadow-md mb-6">
            <p className={`text-md font-semibold tracking-tight text-yellow-800`}>Review Your Information</p>
            <p className={`mb-2 text-sm font-normal tracking-tight text-black`}>Please review your contact information and update anything incorrect/outdated. Please submit this form even if your information is correct.</p>

            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    setIsSubmitting(true);

                    // todo: figure out how to get the values with the form schema
                    const rawEntrySchema = new FormData(event.target);
                    // const entrySchema = Object.fromEntries(rawEntrySchema.entries());

                    var object = {};
                    rawEntrySchema.forEach((value, key) => {
                        // Reflect.has in favor of: object.hasOwnProperty(key)
                        if(!Reflect.has(object, key)){
                            object[key] = value;
                            return;
                        }
                        if(!Array.isArray(object[key])){
                            object[key] = [object[key]];
                        }
                        object[key].push(value);
                    });
                    var entrySchema = JSON.stringify(object);

                    // todo: validate
                    let formEntry = new FormData();
                    formEntry.append('form_id', form.id);
                    formEntry.append('user_id', auth.user.id);
                    formEntry.append('form_schema', form.schema);
                    formEntry.append('entry_schema', entrySchema);

                    authenticatedApi.post('form-entries', formEntry).then(response => {
                        setIsSubmitted(true);

                        // this will hide the form after 8 seconds (keeps it from re-appearing if the user navigates away and back without reloading)
                        setTimeout(() => {
                            auth.user.contact_info_updated_at = new Date();
                        }, 8000);
                    }).catch(e => {
                        setIsError(true); // todo: get actual error message
                    }).finally(() => {
                        setIsSubmitting(false);
                    });
                }}
            >
                {formSchema !== '' && (
                    <ReactFormeoRenderer formData={formSchema} values={{
                        '6c34fa8b-7299-4dc4-8c17-dabf1935e99a': auth.user.phone_number,
                        'cc865cd8-66ff-41ec-bc61-915bb2e3c6e3': auth.user.email,
                    }} />
                )}

                <Button
                    mt={4}
                    colorScheme='brand'
                    isLoading={isSubmitting}
                    spinner={<BeatLoader size={8} color='white' />}
                    type='submit'
                >
                    Submit
                </Button>
                {isError && (
                    <Box mt={4}>
                <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    There was an error submitting the form.
                </Alert>
                </Box>
            )}
            </form>
        </div>
    );
};

export default PersonalInfoUpdate;

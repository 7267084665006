import { addMonths, differenceInCalendarDays, format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { apiUrl, authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";

export default function RenewalCard(props) {
    let auth = useAuth();

    let classMap = {
        good: {
            bg: 'bg-green-50',
            title: 'text-green-500',
            value: 'text-green-800',
        },
        warning: {
            bg: 'bg-yellow-50',
            title: 'text-yellow-700',
            value: 'text-black',
        },
        bad: {
            bg: 'bg-red-50',
            title: 'text-red-500',
            value: 'text-red-800',
        },
    };

    let typeMap = {
        physical: {
            name: 'Physical Expiration',
            column: 'physical_date',
            // todo: allow changing required physical date
            // offset: '1 year',
            renewal_length_months: '24',
        },
        sexual_harassment: {
            name: 'Sexual Harassment Renewal',
            column: 'hm126c_date',
            renewal_length_months: '12',
            link: '/documents/forms/0ba9958b-3abd-4f59-8b42-86296165a16d',
        },
        hazmat: {
            name: 'Hazmat Renewal',
            column: 'hazmat_date',
            renewal_length_months: '36',
            link: '/documents/forms/717a1f4b-3cdc-40a4-a940-e8fb81137018',
        },
        mvr: {
            name: 'MVR',
            column: 'mvr_date',
            renewal_length_months: '12',
            link: '/documents/forms/af92c211-5b04-4b62-bc4d-d258a4e05be0',
        },
    };

    const type = typeMap[props.type];

    let colorType = 'bad';
    let val = 'N/A';
    let daysRemaining = 0;
    if(auth.user.hasOwnProperty(type.column)) {
        let renewalDate = addMonths(parseISO(auth.user[type.column]), type.renewal_length_months);
        daysRemaining = differenceInCalendarDays(renewalDate, new Date());

        if(daysRemaining > 180) {
            colorType = 'good';
            val = `Good until ${format(renewalDate, 'MM/dd/yy')}`;
        } else if(daysRemaining >= 90) {
            colorType = 'good';
            val = `Renew by ${format(renewalDate, 'MM/dd/yy')}`;
        } else if(daysRemaining >= 45) {
            colorType = 'warning';
            val = `Due in ${daysRemaining} days`
        } else {
            // color type is already bad
            val = 'RENEW IMMEDIATELY';
        }

        // if(daysRemaining )
        // val = daysRemaining <= 30 ? 'RENEW IMMEDIATELY' : `Renew by ${format(renewalDate, 'MM/dd/yy')}`;
    }

    return (
        <div className={`flex flex-col items-center block p-6 pb-4 rounded-lg border border-gray-200 shadow-md ${classMap[colorType].bg}`}>
            <p className={`mb-2 text-md font-semibold tracking-tight ${classMap[colorType].title} text-center`}>{type.name}</p>
            <h5 className={`mb-2 text-2xl font-normal tracking-tight ${classMap[colorType].value} text-center`}>{val}</h5>
            {type.hasOwnProperty('link') && daysRemaining < 90 && (
                <Link to={type.link} className="inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                Fill Out
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            )}
            {props.type == 'sexual_harassment' && auth.user[type.column] && parseInt(auth.user[type.column].split('-')[0]) >= 2021 && (
                <a target="_blank" href={`${apiUrl}/sexual-harrassment-certificate?user_id=${auth.user.id}`} className="inline-flex items-center mt-1 md:mt-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                View Certificate
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </a>
            )}
        </div>
    );
}

import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from "react";
import PortalCard from "../Components/PortalCard";
import { authenticatedApi } from "../Api";
import PortalLoader from "../Components/PortalLoader";
import { buildCardsFromResponse } from "../helpers";
import Announcements from "../Components/Announcements";

export default function AllAnnouncements() {
    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">Announcements</h1>
      </div>

      <Announcements detail />
    </>);
}

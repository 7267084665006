import axios from "axios";

let baseUrl;
let apiUrl;

if(window.location.host.indexOf('localhost') !== -1) {
    baseUrl = 'http://localhost:80';
    apiUrl = baseUrl + '/api/';
} else {
    baseUrl = 'https://dashboard.shke.com';//'https://sharkey.appadministrate.com';
    apiUrl = 'https://api.sharkey.appadministrate.com';
}

const tokenName = 'portal-token';

let token = '';
const getAccessToken = () => {
    if(token !== '') {
        return token;
    }

    let checkToken = localStorage.getItem(tokenName);
    if(checkToken !== null) {
        token = checkToken;
    }

    return token;
}

const unauthenticatedApi = axios.create({
    baseURL: apiUrl,
});

const authenticatedApi = axios.create({
    baseURL: apiUrl,
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${getAccessToken()}`
            }
        }
    }
});

const setAccessToken = (accessToken) => {
    localStorage.setItem(tokenName, accessToken);
    token = accessToken;

    authenticatedApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    return true;
}

let settings = null;
const loadSettings = () => {
    if(settings !== null) return;

    unauthenticatedApi.get(`/settings`).then((response) => {
        console.log(response);
    });
}

export {
    baseUrl,
    apiUrl,
    tokenName,

    getAccessToken,
    setAccessToken,

    unauthenticatedApi,
    authenticatedApi,
};

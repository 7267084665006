import * as React from "react";
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
  } from "react-router-dom";
import { authenticatedApi, getAccessToken, request, setAccessToken, tokenName, unauthenticatedApi } from "./Api";

export const AuthContext = React.createContext({
    user: null,
    setUser: (user) => {},
    isLoading: true,
    signIn: (callback) => {},
    signOut: (callback) => {},
  });

export function useAuth() {
    return React.useContext(AuthContext);
}

export function AuthProvider({ children }) {
    let [user, setUser] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
      let checkToken = getAccessToken();
      // console.log(user);
      // console.log(checkToken);
      if(isLoading && user == null/* && checkToken != ''*/) {
        authenticatedApi.get('/users/me').then(response => {
          setUser(response.data);
        }).catch(e => {
          // log user out & redirect back to login screen
        }).finally(() => {
          // setTimeout(() => {
            setIsLoading(false);
          // }, 1000);
        });
      }
    }, [user]);

    let signIn = (driverId, birthday) => {
      return unauthenticatedApi.post('/token/request', {
        driver_id: driverId,
        birthday: birthday,
        token_name: 'portal',
      }).then(response => {
        setAccessToken(response.data.access_token);
        setUser(response.data.user);
        console.log(response.data.user);
      });
    };

    let signOut = () => {
      return new Promise((resolve, reject) => {
        localStorage.removeItem(tokenName);
        authenticatedApi.get('/revoke-token');

        setUser(null);

        resolve();
      });
    };

    let value = { user, setUser, isLoading, signIn, signOut };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
    let auth = useAuth();
    let location = useLocation();

    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}


export function RequireGuest({ children }) {
    let auth = useAuth();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    if (auth.user) {
      return <Navigate to={from} replace />;
    }

    return children;
}

function AuthStatus() {
    let auth = useAuth();
    let navigate = useNavigate();

    if (!auth.user) {
      return <p>You are not logged in.</p>;
    }

    return (
      <p>
        Welcome {auth.user}!{" "}
        <button
          onClick={() => {
            auth.signOut(() => navigate("/"));
          }}
        >
          Sign out
        </button>
      </p>
    );
}




/**
 * This represents some generic auth provider API, like Firebase.
 */
/*
 const fakeAuthProvider = {
    isAuthenticated: false,
    signin(callback) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
    },
    signout(callback) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
    },
  };
*/

import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Profile() {
    let auth = useAuth();

    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">My Wallet</h1>
      </div>

    {/*
    <h2 className="text-green-700 text-2xl mb-3">Employees</h2>
      <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div className="flex flex-col">
            <Box className="flex-auto flex flex-col rounded-lg shadow-lg p-6 border-2 border-slate-100">
                <div className="flex-auto">
                    <p className="text-2xl font-semibold">New Employee Form</p>
                    <Tag size="sm" variant="solid" colorScheme="brand">Corporate</Tag>
                    <p className="text-sm opacity-80 mb-3">Use this form to request email access, tablet & computers, etc. for a new employee.</p>
                </div>
                <Button
                    colorScheme='brand'
                    spinner={<BeatLoader size={8} color='white' />}
                    type="submit"
                    width="100%"
                    as={Link}
                    to="/tools/new-employee"
                    // rightIcon={<FontAwesomeIcon icon="fa-duotone fa-chevrons-right" />}
                    >
                    Access
                </Button>
            </Box>
        </div>
        <div className="flex flex-col">
            <Box className="flex-auto flex flex-col rounded-lg shadow-lg p-6 border-2 border-slate-100">
                <div className="flex-auto">
                    <p className="text-2xl font-semibold">Handbook Builder</p>
                    <Tag size="sm" variant="solid" colorScheme="brand">Corporate</Tag>
                    <p className="text-sm opacity-80 mb-3">Use this form to request email access, tablet & computers, etc. for a new employee.</p>
                </div>
                <Button
                    colorScheme='brand'
                    spinner={<BeatLoader size={8} color='white' />}
                    type="submit"
                    width="100%"
                    // rightIcon={<FontAwesomeIcon icon="fa-duotone fa-chevrons-right" />}
                    disabled={true}
                    >
                    Coming Soon
                </Button>
            </Box>
        </div>
    </div>
    */}
    </>);
}

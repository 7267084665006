import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";


//users/me/notifications

const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/users/me/notifications').then(response => {
            // console.log(response);
            setNotifications(response.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);


    if(isLoading) {
        return (
            <div className="flex flex-col block p-6 bg-white rounded-lg border border-gray-200 shadow-md">
                <PortalLoader text="Loading..." />
            </div>
        );
    }

    // TODO: include button to pop them up
    return (
        <div className={`flex flex-col items-center block p-6 bg-white rounded-lg border border-gray-200 shadow-md ${notifications.length ? 'text-green-500' : 'text-gray-500'}`}>
            <p className="mb-2 text-md font-semibold tracking-tight text-green-500 text-center">{notifications.length ? (`${notifications.length} New ${notifications.length === 1 ? 'Notification' : 'Notifications'}`) : 'No New Notifications'}</p>
            <FontAwesomeIcon size="3x" icon={faBell} />
            {/* <h5 className="mb-2 text-4xl font-normal tracking-tight text-green-800 text-center">30 Days</h5> */}
        </div>
    );
};

export default Notifications;

import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";

const Announcements = (props) => {
    const [announcements, setAnnouncements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const maxToDisplay = 3;
    const isDetailScreen = props.detail ?? false;

    useEffect(() => {
        setIsLoading(true);

        authenticatedApi.get('/announcements/current').then(response => {
            let data = response.data;

            // limit main announcements screen to the max
            if(!isDetailScreen) {
                // data.length = Math.min(data.length, maxToDisplay);
            }

            // if(isDetailScreen) {
            setAnnouncements(data);
            // } else {
                // setAnnouncements(response.data);
                //isDetailScreen
            // }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);


    if(isLoading) {
        return (
            <div className="flex flex-col block p-6 bg-white rounded-lg border border-gray-200 shadow-md">
                <PortalLoader text="Loading..." />
            </div>
        );
    }

    return (
        <div className="flex flex-col block p-6 pb-2 bg-white rounded-lg border border-gray-200 shadow-md">
            {announcements.length === 0 && <em>There are no announcements to display at this time. Check back soon!</em>}
            {announcements.map((announcement, index) => (isDetailScreen || (!isDetailScreen && index < maxToDisplay)) ? (<div key={index}>
                <p className="text-md font-semibold tracking-tight text-green-500"><em className="text-gray-500">{format(parseISO(announcement.start_at || announcement.created_at), 'MM/dd/yy')}:</em> {announcement.title}</p>
                <p className="mb-3 text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: announcement.message}}></p>
            </div>) : null)}
            {announcements.length > maxToDisplay && !isDetailScreen && (
                <Link to={'/announcements'} className="self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                View All
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </Link>
            )}
        </div>
    );
};

export default Announcements;

import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PortalCard from "../Components/PortalCard";
import React, { useEffect, useState } from "react";
import { authenticatedApi } from "../Api";
import { buildCardsFromResponse } from "../helpers";
import PortalLoader from "../Components/PortalLoader";

export default function Education() {
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    authenticatedApi.get(`dashboard-section/E`).then(response => {

      let builtSections = buildCardsFromResponse(response.data);

      setSections(builtSections);
    }).finally(() => {
        setIsLoading(false);
    });
  }, []);

  if(isLoading) {
      return <PortalLoader text="Loading..." />;
  }

    const sections1 = [
      {
        name: 'Compliance',
        cards: [
          {
            name: 'Sexual Harassment Training',
            description: 'Expires 12/31/2022',
            button: {
              completed: true,
              linkTo: '/documents/sexual-harrassment',
              // target: '_self',
              text: 'Filled Out - Thank You!',
            },
            /*
            button: {
              link: '/documents/IL Sex Harassment Power Point 2020.pdf',
              text: 'Review Information',
            }
            */
          },
          {
            name: 'Hazmat Test',
            description: 'Expired 1/31/2022',
            button: {
              link: '/documents/HM126F-HM232 Test.pdf',
              text: 'Review Information',
            }
          },
        ],
      },
      {
        name: 'XRS',
        cards: [
          {
            name: 'XRS Training Booklet',
            button: {
              link: '/documents/Updated Omnitracs XRS Mobile ELD Driver Training.pdf',
              text: 'Read',
            },
          },
          {
            name: 'XRS Instruction Info',
            // description: '2022 card'
            button: {
              link: '/documents/XRS Instruction Booklet.pdf',
              text: 'Read',
            },
          },
          //XRS ELD Cab Card
          /*
          {
            name: 'XRS How-to Videos',
            // description: '2022 card'
            button: {
              link: '#',
              text: 'Read',
            },
          }
          */
        ]
      },
      {
        name: 'Other',
        cards: [
          {
            name: 'GWCC Learning Library',
            description: <>Username: MCP16737F<br/>Password: greatwest</>,
            buttons:
            [
              {
                link: 'https://greatwest.infinit-i.net/',
                text: 'Access',
              },
              {
                link: '/documents/Great West Learning Library catalog 3-21.pdf',
                text: 'View Instructions',
              }
            ],
          },
          /*
          {
            name: 'GWCC Learning Library Instructions',
            // description: <>Username: MCP16737F<br/>Password: greatwest</>,

            button: {
              link: '/documents/Great West Learning Library catalog 3-21.pdf',
              text: 'View',
            }
          },
          */
          {
            name: 'Workplace Injuries',
            description: <>TCC Account Number 30231<br/>Call 866-323-4227</>,
            buttons: [
              {
                link: '/documents/SharkeyTransportation_Poster.pdf',
                text: 'Learn What To Do',
              },
              {
                link: '/documents/SharkeyTransportation_Workflow.pdf',
                text: 'View Process',
              },
            ],
          },
        ]
      }
    ];

    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">Education</h1>
      </div>

      {sections.map((section, sectionIndex) => (<React.Fragment key={sectionIndex}>
        <h2 className="text-green text-xl mb-2">{section.name}</h2>
        <div className="grid auto-cols-fr md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {section.cards.map((card, ind) => (<PortalCard key={ind} card={card} />))}
        </div>
      </React.Fragment>))}
    </>);
}

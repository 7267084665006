import * as React from "react";
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
  } from "react-router-dom";
import { authenticatedApi, getAccessToken, request, setAccessToken, tokenName, unauthenticatedApi } from "./Api";

export const SettingsContext = React.createContext({
    settings: null,
    isLoading: true,
    // signIn: (callback) => {},
    // signOut: (callback) => {},
  });

export function useSettings() {
    return React.useContext(SettingsContext);
}

export function SettingsProvider({ children }) {
    let [settings, setSettings] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
      if(isLoading && settings == null/* && checkToken != ''*/) {
        unauthenticatedApi.get('/settings/all').then(response => {
          setSettings(response.data);
        }).catch(e => {
          //
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }, []);

    let value = { ...settings, isLoading };

    return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}

import { addMonths, differenceInCalendarDays, format, parse, parseISO } from "date-fns";
import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthProvider";
import { authenticatedApi } from "../Api";
import PortalLoader from "./PortalLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import { Link } from "react-router-dom";

export default function AdditionalInformation(props) {
    console.log('additional info')
    if(!props.hasOwnProperty('data') || props.data === null || typeof props.data === 'string') return null;

    return (<>
        {props.data.map((field, index) => {
            console.log(field);
            if(!field.show_field) return null;

            return (
                <React.Fragment key={index}>
                    {field.label && field.show_label && (<p className="text-md font-semibold tracking-tight text-green-500">{field.label}</p>)}
                    <p className="mb-3 text-sm font-normal tracking-tight" dangerouslySetInnerHTML={{__html: field.content}}></p>
                </React.Fragment>
            );
        })}
    </>);
}

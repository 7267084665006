import { Field, Form, Formik } from 'formik';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stack,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
  FormErrorMessage,
} from '@chakra-ui/react'

import { QuizzBuilder, Quiz } from "react-quizzes"
// import "react-quizzes/lib/assets/antd.css";


const sexualHarrassment = [
    {
      "id": "0bd6e4d2-c144-4bd9-a416-548c86aa7514",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Hanging around, touching, hugging, kissing, patting, pinching, bumping, cornering, blocking, and impeding are all forms of physical sexual harassment.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "f3a9e82b-74b0-4dcf-857c-3e35ad67bb70",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Sexual imposition, sexual coercion, sexual bribery, seductive harassment, and gender harassment are all forms of sexual harassment.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "32e08d78-b510-43c5-9d4e-9d478b37914f",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Ignoring sexually harassing behavior will cause it to go away or correct itself.&nbsp;There is no need to report it.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "eece786a-a08e-4abd-a3d7-60da4acda03b",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Paying unwanted attention, displaying sexual suggestive visuals, sexually orientated entertainment, and gestures are all form of nonverbal sexual harassment.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "efd987ca-24fb-4137-b2ad-4dbfae1b63cb",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>When filing a complaint with the harasser’s supervisor it is best to have dates, time, and occurrences to help document your complaint.&nbsp;Your complaint will still be filed and taken seriously if you do not have this information.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "f4cc9ae6-809c-4171-9e12-9ad838956806",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>When filing a complaint, you want to let the harasser know that his/her conduct is unwelcome or unwanted.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "fd9c8878-1591-473e-82da-af69c14b8d81",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Sexual harassment only happens with the opposite sex.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "6d72334d-8e9e-4314-b5c9-1f77a07357ef",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>People “ask” to be sexually harassed.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "485a08fa-ca27-4968-b88f-f41200dd6f90",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Turning work discussions into sexual topics, jokes, sexual comments, whistling, sexual connotations or innuendoes, are not necessarily/always considered sexual harassment.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    },
    {
      "id": "7e3bc888-186e-4333-98cf-70a9c048b7bc",
      "element": "RadioButtons",
      "required": true,
      "questions": {
        "en": "<p><strong>Sexual harassment is a form of sex discrimination that violates Title VII of the Civil Rights Act of 1964 as well as Illinois Human Rights Act, 775 ILCS 5/2-109.</strong></p>"
      },
      "options": [
        {
          "value": "1",
          "text": {
            "en": "True"
          }
        },
        {
          "value": "2",
          "text": {
            "en": "False"
          }
        }
      ]
    }
];


function SexualHarrassment() {
    return (<>
        <div className="mb-6">
            <h1 className="text-green text-3xl">Sexual Harrassment</h1>
            {/* <p className="text-sm text-green text-italic">Note: you have already filled out your sexual harrassment form.</p> */}
        </div>

        <div className="max-w-3xl">

        <Quiz data={sexualHarrassment} />
        </div>

        {/*
        <Formik
        initialValues={{  }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
          {(props) => (
              <Form>


        <div className="max-w-3xl">
        <p className="text-sm mb-3">I hereby authorize Sharkey Transportation, Inc. to use my photo and/or information related to my experiences with Sharkey Transportation, Inc.</p>

        <p className="text-sm mb-3">I understand this information may be used in publications, including electronic publications, audiovisual presentations, promotional literature, advertising, and media and/or other similar ways.</p>

        <p className="text-sm mb-3">My consent is freely given without expecting payment.</p>

        <p className="text-sm">I release Sharkey Transportation, Inc. and their respective employees, officers, and agents from any and all liability which may arise from the use of such news media stories, promotional materials, written articles, videotape, and/or photographs.</p>

        <br/>
        <strong>I prefer:</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['complete']}>
            <Stack>
                <Checkbox value='complete'>My complete name be used</Checkbox>
                <Checkbox value='initial'>My first name and last initial only be used</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Photo(s)</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['photos']}>
            <Stack>
                <Checkbox value='photos'> I approve sharing my photo(s)</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Work Anniversary and Milestone Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['anniversary']}>
            <Stack>
                <Checkbox value='anniversary'> I approve sharing my photo(s)</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Work Anniversary and Milestone Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['anniversary']}>
            <Stack>
                <Checkbox value='anniversary'> I approve sharing my Work Anniversary and Milestone achievements</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Birthday Week Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['birthday']}>
            <Stack>
                <Checkbox value='birthday'> I approve sharing my Birthday Week</Checkbox>
            </Stack>
        </CheckboxGroup>

<br/>
        <strong>Opt-Out</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['optout']}>
            <Stack>
                <Checkbox value='optout'> I prefer to completely opt-out</Checkbox>
            </Stack>
        </CheckboxGroup>

        <p className="text-sm mb-3">I understand that I can withdraw my consent at any time in writing and that the use of any of my photos or other information authorized by this release will immediately cease.</p>

        <p className="text-sm mb-3">I understand if any photos and/or information were already distributed, these will not be removed from those existing documents or media.</p>

        <p className="text-sm text-italic">I understand that filling out my name below constitutes a digital signature.</p>

        <Field name='first_name'>
        {({ field, form }) => (
            <FormControl className="col-span-3" isInvalid={form.errors.first_name && form.touched.first_name}>
            <FormLabel htmlFor='first_name'>Full Name</FormLabel>
            <Input {...field} id='first_name' />
            <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
            </FormControl>
        )}
        </Field>

        <p className="text-sm mb-3">The signature of a parent or legal guardian is required if the above individual is under the age of 18.</p>
        </div>

        <Button
              mt={4}
              colorScheme='brand'
              type='submit'
            >
              Update
            </Button>
        </Form>
          )}
      </Formik>
      */}
    </>);
}

export default SexualHarrassment;

import { Field, Form, Formik } from 'formik';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stack,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
  FormErrorMessage,
} from '@chakra-ui/react'

function MediaRelease() {
    return (<>
        <div className="mb-6">
            <h1 className="text-green text-3xl">Media Release</h1>
            <p className="text-sm text-green text-italic">Note: you have already filled out your media release form.</p>
        </div>

        <Formik
        initialValues={{  }}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false)
          }, 1000)
        }}
      >
          {(props) => (
              <Form>


        <div className="max-w-3xl">
        {/* <p className="text-sm mb-3"></p> */}
        <p className="text-sm mb-3">I hereby authorize Sharkey Transportation, Inc. to use my photo and/or information related to my experiences with Sharkey Transportation, Inc.</p>

        <p className="text-sm mb-3">I understand this information may be used in publications, including electronic publications, audiovisual presentations, promotional literature, advertising, and media and/or other similar ways.</p>

        <p className="text-sm mb-3">My consent is freely given without expecting payment.</p>

        <p className="text-sm">I release Sharkey Transportation, Inc. and their respective employees, officers, and agents from any and all liability which may arise from the use of such news media stories, promotional materials, written articles, videotape, and/or photographs.</p>

        <br/>
        <strong>I prefer:</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['complete']}>
            <Stack>
                <Checkbox value='complete'>My complete name be used</Checkbox>
                <Checkbox value='initial'>My first name and last initial only be used</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Photo(s)</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['photos']}>
            <Stack>
                <Checkbox value='photos'> I approve sharing my photo(s)</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Work Anniversary and Milestone Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['anniversary']}>
            <Stack>
                <Checkbox value='anniversary'> I approve sharing my photo(s)</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Work Anniversary and Milestone Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['anniversary']}>
            <Stack>
                <Checkbox value='anniversary'> I approve sharing my Work Anniversary and Milestone achievements</Checkbox>
            </Stack>
        </CheckboxGroup>

        <br/>
        <strong>Birthday Week Recognition</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['birthday']}>
            <Stack>
                <Checkbox value='birthday'> I approve sharing my Birthday Week</Checkbox>
            </Stack>
        </CheckboxGroup>

<br/>
        <strong>Opt-Out</strong>
        <CheckboxGroup colorScheme='brand' defaultValue={['optout']}>
            <Stack>
                <Checkbox value='optout'> I prefer to completely opt-out</Checkbox>
            </Stack>
        </CheckboxGroup>

        <p className="text-sm mb-3">I understand that I can withdraw my consent at any time in writing and that the use of any of my photos or other information authorized by this release will immediately cease.</p>

        <p className="text-sm mb-3">I understand if any photos and/or information were already distributed, these will not be removed from those existing documents or media.</p>

        <p className="text-sm text-italic">I understand that filling out my name below constitutes a digital signature.</p>

        <Field name='first_name'>
        {({ field, form }) => (
            <FormControl className="col-span-3" isInvalid={form.errors.first_name && form.touched.first_name}>
            <FormLabel htmlFor='first_name'>Full Name</FormLabel>
            <Input {...field} id='first_name' />
            <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
            </FormControl>
        )}
        </Field>

        <p className="text-sm mb-3">The signature of a parent or legal guardian is required if the above individual is under the age of 18.</p>
        </div>

        <Button
              mt={4}
              colorScheme='brand'
            //   isLoading={props.isSubmitting}
            //   spinner={<BeatLoader size={8} color='white' />}
              type='submit'
            >
              Update
            </Button>
        </Form>
          )}
      </Formik>
    </>);
}

export default MediaRelease;

import { format, parse, parseISO } from "date-fns";
import { useState, useEffect } from "react";
import { authenticatedApi } from "../Api";
import { useSettings } from "../SettingsProvider";
import PortalLoader from "./PortalLoader";

const FuelLink = () => {
    const settings = useSettings();

    if(settings.isLoading) {
        return (
            <div className="flex flex-col block p-6 bg-white rounded-lg border border-gray-200 shadow-md">
                <PortalLoader text="Loading..." />
            </div>
        );
    }

    if(!settings.hasOwnProperty('fuel_links')) {
        return (
            <div className="flex flex-col block p-6 bg-white rounded-lg border border-gray-200 shadow-md">
                <em>N/A</em>
            </div>
        );
    }

    return (
        <div className="grid auto-cols-fr grid-cols-12 gap-6">
            {settings.fuel_links.map((fuelLink, index) => (
                <a key={index} href={fuelLink.link} target="_blank" className="col-span-12 md:col-span-6 block items-center px-6 py-4 text-lg font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
            {fuelLink.name}
            </a>
            ))}
        </div>
    );

};

export default FuelLink;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import App from './App';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    brand: {
      50: '#c8e8c9',
      100: '#a7d8ab',
      200: '#85c88c',
      300: '#63b86e',
      400: '#4a9f58',
      500: '#387b46',
      600: '#275833',
      700: '#275833',
      800: '#16351c',
      900: '#011402',
    },
  },
  components: {
    Badge: {}
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

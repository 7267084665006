import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PortalCard from "../Components/PortalCard";
import React, { useEffect, useState } from "react";
import { authenticatedApi } from "../Api";
import PortalLoader from "../Components/PortalLoader";

export default function Contacts() {
    let auth = useAuth();

    const [contacts, setContacts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
      setIsLoading(true);
        authenticatedApi.get('/contacts?paginate=-1').then(response => {
          // console.log(response.data.data);
          setContacts(response.data.data);
        }).finally(() => {
            setIsLoading(false);
        });

    }, []);

    const filteredContacts = () => {
      if(searchTerm.length < 2) {
        return contacts;
      }

      const lowerSearch = searchTerm.toLocaleLowerCase();

      return contacts.filter(value => {
        return JSON.stringify(value).toLocaleLowerCase().indexOf(lowerSearch) !== -1;
        return (
          value.name.toLocaleLowerCase().indexOf(lowerSearch !== -1)
          || value.description.toLocaleLowerCase().indexOf(lowerSearch !== -1)
          || value.phone.toLocaleLowerCase().indexOf(lowerSearch !== -1)
        );
      });
    }

    if(isLoading) {
      return <PortalLoader text="Loading..." />;
    }

    return (<>
      <div className="mb-6 grid auto-cols-fr grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-9">
          <h1 className="text-green text-3xl">Contacts</h1>
        </div>
        <div className="col-span-12 lg:col-span-3">
          {/* <FormControl> */}
            {/* <FormLabel htmlFor='email'>Search</FormLabel> */}
            <Input placeholder="Search..." value={searchTerm} onChange={(event) => {
              setSearchTerm(event.target.value);
              console.log(event.target.value);
            }} />
          {/* </FormControl> */}

        </div>
      </div>

      <div className="grid auto-cols-fr md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        {filteredContacts().map((contact, contactIndex) => {
          const card = {
            ...contact,
          };

          if(contact.phone) {
            card.buttons = [
              {
                link: `tel:${contact.phone}`,
                text: `Call ${contact.phone}`,
              }
            ];
          }

          console.log(card.additional_info);

          return (
            <PortalCard key={contactIndex} card={card} className="justify-between" />
          );
        })}
      </div>

    </>);
}

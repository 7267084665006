import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
  Input,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PortalCard from "../Components/PortalCard";
import React, { useEffect, useState } from "react";
import PortalLoader from "../Components/PortalLoader";
import { authenticatedApi } from "../Api";
import { buildCardsFromResponse } from "../helpers";

export default function FormsDocuments() {
  let auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setIsLoading(true);
    authenticatedApi.get(`dashboard-section/F`).then(response => {

      let builtSections = buildCardsFromResponse(response.data);

      setSections(builtSections);
    }).finally(() => {
        setIsLoading(false);
    });
  }, []);

  const filteredSections = () => {
    if(searchTerm.length < 2) {
      return sections;
    }

    const lowerSearch = searchTerm.toLocaleLowerCase();

    const clonedSections = JSON.parse(JSON.stringify(sections));

    for(let sectionIndex in clonedSections) {
      let cards = clonedSections[sectionIndex].cards;

      cards = cards.filter(value => {
        return JSON.stringify(value).toLocaleLowerCase().indexOf(lowerSearch) !== -1;
      });

      clonedSections[sectionIndex].cards = cards;
    }

    return clonedSections.filter(value => {
      return value.cards.length > 0;
    });
  }

  if(isLoading) {
      return <PortalLoader text="Loading..." />;
  }

  return (<>
      <div className="mb-6 grid auto-cols-fr grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-9">
          <h1 className="text-green text-3xl">Forms & Documentation</h1>
        </div>
        <div className="col-span-12 lg:col-span-3">
          {/* <FormControl> */}
            {/* <FormLabel htmlFor='email'>Search</FormLabel> */}
            <Input placeholder="Search..." value={searchTerm} onChange={(event) => {
              setSearchTerm(event.target.value);
              console.log(event.target.value);
            }} />
          {/* </FormControl> */}

        </div>
      </div>

      {filteredSections().map((section, sectionIndex) => (<React.Fragment key={sectionIndex}>
        {section.name && (<h2 className="text-green text-xl mb-2">{section.name}</h2>)}
        <div className="grid auto-cols-fr md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {section.cards.map((card, ind) => (<PortalCard key={ind} card={card} />))}
        </div>
      </React.Fragment>))}
    </>);
}

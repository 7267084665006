import {useState, useEffect, createContext} from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useResolvedPath,
  useMatch,
  Navigate,
  Outlet,
  NavLink,
} from "react-router-dom";

import logo from './logo.svg';
import './App.scss';
import Login from "./Routes/Login";
import { AuthContext, AuthProvider, RequireAuth, RequireGuest, useAuth } from "./AuthProvider";
import ForgotPassword from "./Routes/ForgotPassword";
import PortalLoader from "./Components/PortalLoader";
import Dashboard from "./Routes/Dashboard";
import SetNewPassword from "./Routes/SetNewPassword";
import Logout from "./Routes/Logout";
import Partners from "./Routes/Partners";
import Tools from "./Routes/Tools";
import NewEmployeeForm from "./Routes/Tools/NewEmployeeForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faArrowRightFromBracket, faLaptop, faMemoPad, faObjectsColumn, faToolbox, faWallet } from "@fortawesome/pro-duotone-svg-icons";
import Wallet from "./Routes/Wallet";
import FormsDocuments from "./Routes/FormsDocuments";
import Education from "./Routes/Education";
import Profile from "./Routes/Profile";
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import MediaRelease from "./Routes/Forms/MediaRelease";
import SexualHarrassment from "./Routes/Forms/SexualHarrassment";
import Contacts from "./Routes/Contacts";
import { FormFill } from "./Routes/Forms/FormFill";
import { unauthenticatedApi } from "./Api";
import { SettingsProvider } from "./SettingsProvider";
import AllAnnouncements from "./Routes/AllAnnouncements";

export default function App() {
  let auth = useAuth();

  return (
    <SettingsProvider>
    <AuthProvider>
      <div className="App flex min-h-screen">
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />

            {/* Guest Routes */}
            <Route path="/login" element={<RequireGuest><Login/></RequireGuest>} />
            {/* <Route path="/forgot-password" element={<RequireGuest><ForgotPassword/></RequireGuest>} /> */}
            {/* <Route path="/forgot-password/:email/:token" element={<RequireGuest><SetNewPassword/></RequireGuest>} /> */}

            {/* Authenticated Routes */}
            <Route path="/logout" element={<Logout/>} />
            <Route path="/profile" element={<Profile/>} />

            <Route path="/announcements" element={<RequireAuth><AllAnnouncements /></RequireAuth>} />

            <Route path="/wallet" element={<RequireAuth><Wallet /></RequireAuth>} />

            <Route path="/documents" element={<RequireAuth><FormsDocuments /></RequireAuth>} />
            <Route path="/documents/media-release" element={<RequireAuth><MediaRelease /></RequireAuth>} />
            <Route path="/documents/sexual-harrassment" element={<RequireAuth><SexualHarrassment /></RequireAuth>} />
            <Route path="/documents/forms/:uuid" element={<RequireAuth><FormFill /></RequireAuth>} />

            <Route path="/education" element={<RequireAuth><Education /></RequireAuth>} />

            <Route path="/contacts" element={<RequireAuth><Contacts /></RequireAuth>} />


            {/* <Route path="/tools/new-employee" element={<RequireAuth><NewEmployeeForm /></RequireAuth>} /> */}

            {/* Admin Routes */}

            <Route path="*" element={<p>404</p>}/>
          </Route>
        </Routes>
      </div>
    </AuthProvider>
    </SettingsProvider>
  );
}

function RouterMenuLink({item, onClick}) {
  let resolved = useResolvedPath(item.link);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
  <MenuItem
    className='mb-1 block flex items-center ml-6 px-3 py-3 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white'
    icon={<FontAwesomeIcon icon={item.icon} />}
    active={match}
    onClick={onClick}
  >
    {item.name}
    <Link to={item.link} />
  </MenuItem>
  );
}

function Layout() {
  let auth = useAuth();
  const [toggled, setToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const navLinks = [
    {
      name: 'Dashboard',
      link: '/',
      icon: faObjectsColumn,// 'fa-duotone fa-objects-column',
    },
    {
      name: 'My Wallet',
      link: '/wallet',
      icon: faWallet,// 'fa-duotone fa-objects-column',
    },
    {
      name: 'Forms & Documents',
      link: '/documents',
      icon: faMemoPad,// 'fa-duotone fa-objects-column',
    },
    {
      name: 'Education',
      link: '/education',
      icon: faLaptop,// 'fa-duotone fa-objects-column',
    },
    {
      name: 'Contacts',
      link: '/contacts',
      icon: faAddressBook,// 'fa-duotone fa-objects-column',
    },
    // Locations
    // Profile
  ];

  return (
    <>
    {/* min-h-screen sticky top-0 flex-initial basis-1/5 shrink-0  flex flex-col */}
      {!auth.isLoading && auth.user && (
        <aside className="bg-white md:shadow-md">
        <ProSidebar
          toggled={toggled}
          onToggle={handleToggleSidebar}
          className="md"
        >
        <SidebarHeader>
          <div className="px-12 py-6 md:py-12 flex-initial">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
                  {/* className={({ isActive }) => `block flex items-center ml-12 px-4 py-4 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white ${isActive ? 'bg-green-500/100 !text-white' : ''}`} */}
                {/* // className={`ml-12 p-4 rounded-l text-white text-lg font-semibold hover:bg-gray-100 hover:text-green ${item.active ? 'bg-gray-100 text-green' : ''}`} */}
            {navLinks.map((item, index) => {
              return <RouterMenuLink key={index} item={item} onClick={() => {
      handleToggleSidebar(false);
    }}/>
              {/* let resolved = useResolvedPath(item.link); */}
              {/* let match = useMatch({ path: resolved.pathname, end: true }); */}
              return (
              <MenuItem
                key={`nav-${index}`}
                className='mb-1 block flex items-center ml-6 px-4 py-4 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white'
                icon={<FontAwesomeIcon icon={item.icon} />}
                active={false}
                onClick={() => {
                  handleToggleSidebar(false);
                }}
              >
                {item.name}
                <Link to={item.link} />
              </MenuItem>
            )})}

          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu>
            {auth.user.is_admin === true && (
            <MenuItem
                className='mb-1 block flex items-center ml-6 px-3 py-3 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white'
                icon={<FontAwesomeIcon icon={faToolbox} />}
                // active={true}
                onClick={() => {
                  handleToggleSidebar(false);
                }}
            >
              Admin
              <a
                href={window.location.href.indexOf('localhost') !== -1 ? 'http://localhost:3001' : '/admin'}
                target="_blank"
                ></a>
            </MenuItem>
            )}

            <MenuItem
                className='mb-1 block flex items-center ml-6 px-3 py-3 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white'
                icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                // active={true}
                onClick={() => {
                  handleToggleSidebar(false);
                }}
            >
              Log Out
              <Link to={'/logout'} />
            </MenuItem>
          </Menu>
        </SidebarFooter>

        </ProSidebar>
        </aside>
      )}
      {false && !auth.isLoading && auth.user && (
      <aside className="min-h-screen sticky top-0 flex-initial basis-1/5 shrink-0 bg-white shadow-md flex flex-col">
        <div className="py-12 px-12 flex-initial">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        <div className="flex-auto">
          <ul className="pb-6 sticky top-6">
            {navLinks.map((item, index) => (
              <li
                key={`nav-${index}`}
                className='mb-1'
                // className={`ml-12 p-4 rounded-l text-white text-lg font-semibold hover:bg-gray-100 hover:text-green ${item.active ? 'bg-gray-100 text-green' : ''}`}
              >
                <NavLink
                  to={item.link}
                  className={({ isActive }) => `block flex items-center ml-12 px-4 py-4 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white ${isActive ? 'bg-green-500/100 !text-white' : ''}`}
                >
                  <FontAwesomeIcon icon={item.icon} style={{marginRight: '0.5rem'}} />
                  <span className="flex-auto">
                    {item.name}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-initial">
          <ul className="pb-6">
          <li>
              <a
                href={window.location.href.indexOf('localhost') !== -1 ? 'http://localhost/admin' : '/admin'}
                target="_blank"
                className={`block flex items-center ml-12 px-4 py-4 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white`}
              >
                <FontAwesomeIcon icon={faToolbox} style={{marginRight: '0.5rem'}} />
                <span className="flex-auto">
                  Admin
                </span>
              </a>
            </li>
            <li>
              <NavLink
                to={'/logout'}
                className={({ isActive }) => `block flex items-center ml-12 px-4 py-4 rounded-l text-green-500 text-lg font-semibold leading-tight transition duration-150 bg-gray-100/10 hover:bg-green-500/100 hover:text-white ${isActive ? 'bg-green-500/100 !text-white' : ''}`}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} style={{marginRight: '0.5rem'}} />
                <span className="flex-auto">
                  Log Out
                </span>
              </NavLink>
            </li>
          </ul>

        </div>
      </aside>
      )}
      <main className="flex-auto md:p-12">
          {auth.isLoading === true && (<PortalLoader text="Loading..." />)}

          {auth.isLoading !== true && (<>
            <div className="bg-white sticky top-0 px-6 py-3 flex flex-row items-center justify-between shadow-sm md:hidden">
              <div className="flex-initial">
                <div onClick={() => handleToggleSidebar(true)} className="sidebar-toggle-btn inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded-lg hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300">
                  <FontAwesomeIcon icon={faBars} style={{marginRight: '0.5rem'}} />
                  Menu
                </div>
              </div>
              <div className="flex-initial basis-1/6 ml-3">
                <img src={logo} className="App-logo" alt="logo" />
              </div>
            </div>
            <div className="p-6 md:p-0"><Outlet /></div>
          </>)}
      </main>
    </>
  );
}

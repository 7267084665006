import {
    Button,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
// import { FormeoEditor, FormeoRenderer } from 'formeo'
// import 'formeo/dist/formeo.min.css';// from 'formeo';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { authenticatedApi } from '../../Api';
import { useAuth } from '../../AuthProvider';
import PortalLoader from '../../Components/PortalLoader';
import { ReactFormeoEditor, ReactFormeoRenderer } from './Formeo';
import './FormFill.scss';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const FormFill = () => {
    const { uuid = null } = useParams();
    let auth = useAuth();

    const [form, setForm] = useState(null);
    const [formSchema, setFormSchema] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        authenticatedApi.get(`forms/${uuid}`).then(response => {
            let schema = response.data.schema;
            let formData = typeof schema !== 'string' ? JSON.stringify(schema) : schema;

            // fix null objects
            // todo: this probably needs adjustment
            // formData = formData.replaceAll('null', '""');

            setForm(response.data);
            setFormSchema(formData);
        }).finally(() => {
            setIsLoading(false);
        });

    }, [uuid]);

    if(isLoading || form === null) {
        return <PortalLoader text="Loading..." />;
    }

    // if is submitted, display success message
    if(isSubmitted !== false) {
        return (<>
            <div className="mb-6">
                <h1 className="text-green text-3xl">{form.name}</h1>
            </div>
            <div className="max-w-4xl">
                {!isError && (
                <Alert status='success' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    {isSubmitted === true ? 'The form was successfully submitted.' : isSubmitted}
                </Alert>
                )}
                {!isError && Object.values(incorrectAnswers).length > 0 && (
                    <Box mt={4}>
                        <Alert status='warning' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>The following answers were incorrect:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(incorrectAnswers).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}

                {isError && (
                    <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                        <AlertIcon />
                        {isError === true ? 'There was an error submitting the form.' : isError}
                    </Alert>
                )}
            </div>
        </>);
    }

    // if error, display it with the form

    // const [quizSchema, setQuizSchema] = useState([]);
    return (<>
        <div className="mb-6">
            <h1 className="text-green text-3xl">{form.name}</h1>
        </div>
        <div className="max-w-4xl">
            {isError && (
                <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                    <AlertIcon />
                    {isError === true ? 'There was an error submitting the form.' : isError}
                </Alert>
            )}
            <form
                onSubmit={async (event) => {
                    event.preventDefault();

/*
                    console.log(formSchema);
                    var entrySchema = JSON.stringify(object);
                    console.log(entrySchema);
                    return;
*/


                    console.log(event);

                    setIsSubmitting(true);
                    setValidationErrors([]);
                    setIncorrectAnswers([]);

                    const rawEntrySchema = new FormData(event.target);

                    var object = {};
                    for(let [key, value] of rawEntrySchema) {
                        // handle files first
                        if(value instanceof File) {
                            try {
                                const b64file = await getBase64(value);

                                // we perform this same logic to prevent the raw File object from being added
                                if(Reflect.has(object, key)) {
                                    if(!Array.isArray(object[key])) {
                                        object[key] = [object[key]];
                                    }

                                    object[key].push(b64file);
                                } else {
                                    object[key] = b64file;
                                }
                                continue;
                            } catch(e) {
                                console.log(e);
                                continue;
                            }
                        }

                        // handle non-array values / initial values
                        // Reflect.has in favor of: object.hasOwnProperty(key)
                        if(!Reflect.has(object, key)) {
                            console.log('NO REFLECT.HAS FOR ', key);
                            object[key] = value;
                            continue;
                        }

                        // force multi types to be an array when adding another value
                        if(!Array.isArray(object[key])) {
                            console.log('SETTING ARRAY FOR ', key);
                            object[key] = [object[key]];
                        }

                        object[key].push(value);
                    };
                    // console.log(object);
                    var entrySchema = JSON.stringify(object);
                    console.log(entrySchema);

                    let score = ''; // mysql doesn't allow null for decimal/integer even if the column is nullable; use empty string for default value
                    let passed = '';

                    // check for required values matching
                    let requiredValues = {};
                    let correctAnswers = 0;
                    let newValidationErrors = [];
                    let newIncorrectAnswers = [];
                    for(const field of Object.values(JSON.parse(form.schema).fields)) {
                        // the user hasn't answered the question so they have to do that before submitting (this is primarily useful for checkbox/radio options that don't correctly set the required field)
                        if(field.attrs.hasOwnProperty('required') && field.attrs.required) {
                            if(!object.hasOwnProperty(`f-${field.id}`)) {
                                newValidationErrors.push(`"${field.config.label}" <b>is required</b>`);
                            }
                        }

                        if(field.attrs.hasOwnProperty('correct-answer')) {
                            requiredValues[field.id] = field.attrs['correct-answer'];

                            if(!object.hasOwnProperty(`f-${field.id}`) || object[`f-${field.id}`] != field.attrs['correct-answer']) {
                                newIncorrectAnswers.push(field.config.label);//attrs['correct-answer'];
                            } else {
                                correctAnswers++;
                            }
                        }
                    }

                    if(form.scoring_mode !== null && form.scoring_mode.indexOf('scored') !== -1 && Object.keys(requiredValues).length) {
                        score = correctAnswers / Object.keys(requiredValues).length;
                        score = Math.round(score * 100);
                        passed = score >= form.minimum_score ? 1 : 0;
                    }

                    const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
                    newValidationErrors.sort(sortAlphaNum);
                    newIncorrectAnswers.sort(sortAlphaNum);

                    // TODO: check if form type is the type to show answers before submitting
                    if(Object.values(newValidationErrors).length) {
                        setValidationErrors(newValidationErrors);
                        setIsSubmitting(false);
                        return;
                    } else if(form.scoring_mode !== null && form.scoring_mode.indexOf('correctable') !== -1 && Object.values(newIncorrectAnswers).length) {
                        setIncorrectAnswers(newIncorrectAnswers);
                        if(form.scoring_mode == 'correctable') {
                            setIsSubmitting(false);
                            return;
                        }
                    }

                    // todo: validate
                    let formEntry = new FormData();
                    formEntry.append('form_id', form.id);
                    formEntry.append('user_id', auth.user.id);
                    formEntry.append('form_schema', form.schema);
                    formEntry.append('entry_schema', entrySchema);
                    formEntry.append('score', score);
                    formEntry.append('passed', passed);

                    // formEntry.append('file', );

                    authenticatedApi.post('form-entries', formEntry).then(response => {
                        if(form.scoring_mode !== 'scored_correctable') {
                            setIsSubmitted(true);
                        } else if(form.scoring_mode === 'scored_correctable' && passed === 1) {
                            setIsSubmitted(`You got ${score}% correct which is passing. Congrats!`);
                        }

                        if(passed === 0) {
                            setIsError(`You got ${score}% correct but you need ${Math.round(form.minimum_score)}% to pass. Please try again.`);
                        } else {
                            setIsError(false);
                        }

                        // setIsSubmitting(false);
                        // console.log(response.data);
                    }).catch(e => {
                        setIsError(true); // todo: get actual error message
                    }).finally(() => {
                        setIsSubmitting(false);
                    });
                }}
            >
                {formSchema !== '' && (
                    <ReactFormeoRenderer formData={formSchema} />
                )}

                <Button
                    mt={4}
                    colorScheme='brand'
                    isLoading={isSubmitting}
                    spinner={<BeatLoader size={8} color='white' />}
                    type='submit'
                >
                    Submit
                </Button>
                {isError && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            {isError === true ? 'There was an error submitting the form.' : isError}
                        </Alert>
                    </Box>
                )}
                {Object.values(validationErrors).length > 0 && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>Please correct the following issues:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(validationErrors).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}
                {Object.values(incorrectAnswers).length > 0 && (
                    <Box mt={4}>
                        <Alert status='error' style={{width: 'auto', display: 'inline-flex'}}>
                            <AlertIcon />
                            <div>
                                <p>The following answers are incorrect:</p>
                                <ul className='ml-6 list-disc'>
                                    {Object.values(incorrectAnswers).map((validationError, index) => (
                                        <li key={index} dangerouslySetInnerHTML={{__html: validationError}}></li>
                                    ))}
                                </ul>
                            </div>
                        </Alert>
                    </Box>
                )}
            </form>
        </div>
    </>);
}

import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-duotone-svg-icons";
import Announcements from "../Components/Announcements";
import Notifications from "../Components/Notifications";
import RenewalCard from "../Components/RenewalCard";
import FuelLink from "../Components/FuelLink";
import PersonalInfoUpdate from "../Components/PersonalInfoUpdate";

const getTimeOfDayGreeting = () => {
    var data = [
      [0, 4, "Good Night"],
      [5, 11, "Good Morning"],
      [12, 17, "Good Afternoon"],
      [18, 24, "Good Evening"],
    ];
    let hr = new Date().getHours();
    let greeting;

    for(var i = 0; i < data.length; i++){
        if(hr >= data[i][0] && hr <= data[i][1]) {
          greeting = data[i][2];
        }
    }

    return greeting;
};

const todaysDate = () => {
  const date = new Date();
  return date.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });
};

export default function Dashboard() {
    let auth = useAuth();

    return (<>
      <div className="grid auto-cols-fr grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-9">{/* md:col-span-8 */}
          <div className="mb-6">
            <p className="text-sm text-green font-bold">{todaysDate()}</p>
            <h1 className="text-gray-500 text-3xl">{`${getTimeOfDayGreeting()}, ${auth.user.first_name}!`}</h1>
            {/* <h1 className="text-green text-3xl">{`Welcome Back, ${auth.user.first_name}!`}</h1> */}
            <p className="text-sm text-green">Driver ID #{auth.user.driver_id}</p>
          </div>

          <PersonalInfoUpdate/>

          <p className="mb-2 text-md font-semibold tracking-tight text-green-500">Updates & Announcements</p>
          <Announcements/>

          <p className="mt-6 mb-2 text-md font-semibold tracking-tight text-green-500">Find Fuel</p>
          <FuelLink/>

        </div>
        <div className="col-span-12 lg:col-span-3">{/* md:col-span-4 */}
          <div className="grid gap-6">
            <Notifications/>

            <RenewalCard type={'physical'} />
            <RenewalCard type={'sexual_harassment'} />
            <RenewalCard type={'hazmat'} />
            <RenewalCard type={'mvr'} />
          </div>
        </div>
      </div>
    </>);
}

import { useAuth } from "../AuthProvider";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Button,
  Box,
} from '@chakra-ui/react'
import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet,
    NavLink,
  } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from "react";
import PortalCard from "../Components/PortalCard";
import { authenticatedApi } from "../Api";
import PortalLoader from "../Components/PortalLoader";
import { buildCardsFromResponse } from "../helpers";
import { format, parse, parseISO } from "date-fns";

export default function Wallet() {
    let auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [sections, setSections] = useState([]);

    useEffect(() => {
      setIsLoading(true);
      authenticatedApi.get(`dashboard-section/W`).then(response => {

        // check for user's hazmat card
        let hazmatFile = null;
        for(const file of auth.user.files) {
          if(file.name === 'user-hazmat-card') {
            hazmatFile = file;
            // note: don't break - we want to keep iterating this so it always gets the newest
          }
        }

        let hazmatCard;
        if(hazmatFile !== null) {
          hazmatCard = {
            name: 'Mine',
            description: <em>Your hazmat card was uploaded on {format(parseISO(hazmatFile.created_at), 'MM/dd/yy')}.</em>,
            buttons: [
              {
                link: hazmatFile.url,
                text: 'View',
              },
              {
                link: '#',
                type: 'upload',
                id: 'hazmat',
                text: 'Update Card',
              },
            ]
          }
        } else {
          hazmatCard = {
            name: 'Mine',
            description: <em>You have not uploaded a hazmat card. If you wish to become certified, contact us for info.</em>,
            button: {
              link: '#',
              type: 'upload',
              id: 'hazmat',
              text: 'Upload Card',
            },
          };
        }

        let builtSections = buildCardsFromResponse(response.data, {
          Hazmat: [
            hazmatCard
          ]
        });

        setSections(builtSections);
      }).finally(() => {
          setIsLoading(false);
      });
    }, [auth.user.files]);

    if(isLoading) {
        return <PortalLoader text="Loading..." />;
    }

    return (<>
      <div className="mb-6">
        <h1 className="text-green text-3xl">My Wallet</h1>
      </div>

      {sections.map((section, sectionIndex) => (<React.Fragment key={sectionIndex}>
        <h2 className="text-green text-xl mb-2">{section.name}</h2>
        <div className="grid auto-cols-fr md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {section.cards.map((card, ind) => (<PortalCard key={ind} card={card} />))}
        </div>
      </React.Fragment>))}
    </>);
}

import { apiUrl, authenticatedApi } from "./Api";

export const buildCardsFromResponse = (responseData, additionalCards = {}) => {
    let generatedSections = [];
    for(const category in responseData) {
        let cards = [];

        for(const item of responseData[category]) {
            let card = {
                name: item.name,
                description: item.description ?? '',
                additional_info: item.additional_info ?? null,
            };

            if(card.description.indexOf('MCP16737F') !== -1) {
                card.copyButton = {
                    text: 'MCP16737F',
                    label: 'Copy Username',
                };
            }

            // if it has a fileable_id key, it's a file
            if(item.hasOwnProperty('fileable_id'))
            {
                card.button = {
                    link: item.url,
                    text: 'View',
                };
            }
            // if it has a schema, it's a form
            else if(item.hasOwnProperty('schema'))
            {
                // todo: handle completed
                card.button = {
                    linkTo: `/documents/forms/${item.uuid}`,
                    text: 'Fill Out',

                    // completed: true,
                    // text: 'Filled Out - Thank You!',
                };
            }

            cards.push(card);
        }

        if(additionalCards.hasOwnProperty(category)) {
            cards.push(...additionalCards[category]);
        }

        let section = {
            name: category,
            cards
        };

        generatedSections.push(section);
    }

    return generatedSections;
};

export const uploadFile = (params) => {
    let data = new FormData();
    for(const key in params.data) {
        if(key === 'file') continue;
        if(typeof params.data[key] === 'object') {
            data.append(key, JSON.stringify(params.data[key]));
        } else {
            data.append(key, params.data[key]);
        }
    }

    if(typeof params.data.file !== 'undefined' && params.data.file instanceof File) {
        data.append('file', params.data.file);
    }


    return authenticatedApi.post(`/files`, data);
};
